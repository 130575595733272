.authNavbar-container {
    padding: 15px 8px 15px 22px;
    height: 70px;

    .nav-creditsBtn {
        border-width: 1px;
        border-radius: 6px;
        padding: 6px 15px;
        font-size: 15px;
        font-weight: 400;
        color: rgb(104, 16, 199);
        text-align: center;
        cursor: pointer;
        margin: 0px 10px 0px 0px;
        border: 1px solid rgb(194, 255, 80);
        background: transparent;
    }

    .nav-letterBtn {
        margin: 0px 10px 0px 0px;
        background: none rgb(194, 255, 80);
        font-size: 15px;
        font-weight: 400;
        color: rgb(29, 29, 29);
        text-align: center;
        border-radius: 6px;
        padding: 6px 15px;
        border: none;
    }

    .nav-buttons {
        .nav-profile {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

    .menu-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }

    .navbar-logo {
        width: 150px;
        height: 33px;
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
    }

    .nav-menu {
        display: grid;
        grid-template-columns: repeat(5, auto);
        gap: 20px;
        list-style: none;
        text-align: center;
        justify-content: end;
        margin-right: 2rem;
        margin-bottom: 0;
    }

    .nav-links {
        font-size: 15px;
        font-weight: 500;
        color: rgb(107, 114, 128);
        line-height: 1.4;
        text-decoration: none;
    }

    .nav-links:hover {
        color: rgb(101, 16, 193);
        transition: all 0.2s ease-out;
    }

    .fa-bars {
        color: rgb(101, 16, 193);
    }

    .nav-links-mobile {
        display: none;
    }

    .menu-icon {
        display: none;
    }

    @media (max-width: 960px) {
        .navbar {
            position: relative;
        }

        .nav-buttons {
            .nav-profile {
        position: absolute;
            right: 7%;
            }
        }

        .nav-menu {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            position: absolute;
            top: 55px;
            left: -100%;
            opacity: 1;
            transition: all 0.5s ease;
            padding: 20px;
        }

        .nav-menu.active {
            background: #6668f4;
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
        }

        .nav-links {
            text-align: center;
            width: 100%;
            display: table;
            color: #fff;
        }

        .nav-links:hover {
            background-color: #7577fa;
            border-radius: 0;
        }

        .navbar-logo {
            position: absolute;
            top: -7px;
            left: 0;
            transform: translate(25%, 50%);
        }

        .menu-icon {
            display: block;
            position: absolute;
            top: -22px;
            right: 0;
            transform: translate(-100%, 60%);
            font-size: 1.8rem;
            cursor: pointer;
        }

        .fa-times {
            color: rgb(101, 16, 193);
            font-size: 2rem;
        }

        .nav-links-mobile {
            display: block;
            text-align: center;
            padding: 1.5rem;
            margin: 2rem auto;
            border-radius: 4px;
            width: 80%;
            background: #4ad9e4;
            text-decoration: none;
            color: #fff;
            font-size: 1.5rem;
        }

        .nav-links-mobile:hover {
            background: #fff;
            color: #6568f4;
            transition: 250ms;
        }

        Button {
            display: none;
        }
    }
}