.price-page-container {
    background: rgb(101, 16, 193);

    h1 {
     padding-top: 40px;
        font-weight: 700;
        text-align: center;
        font-size: 44px;
        color: rgba(255, 255, 255, 1);
    }

    h2 {
        font-size: 23px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .pricing-columns-container {
        .pricing-column {
            background-color: rgb(255, 255, 255);
            border-radius: 20px;
            padding: 30px 30px 30px 30px;
            margin-top: 15px;
            margin-right: 10px;
            margin-bottom: 15px;
            max-width: 310px;

            h1 {
                font-size: 15px;
                font-weight: 400;
                color: rgba(107, 114, 128, 1);
            }

            h2 {
                font-size: 15px;
                font-weight: 400;
                color: rgba(107, 114, 128, 1);
                text-align: center;
            }

            h3 {
                font-size: 36px;
                font-weight: 400;
                color: rgba(104, 16, 199, 1);
                text-align: center;

                span {
                    font-weight: 400;
                    color: rgba(104, 16, 199, 1);
                    text-align: center;
                }

                .spanTwo {
                    color: rgb(188, 181, 195);
                    font-size: 36px;
                    font-weight: 400;
                }
            }

            .top-up-btn {
                background: rgb(194, 255, 80);
                font-size: 15px;
                font-weight: 400;
                color: #1d1d1d;
                text-align: center;
                border-radius: 6px;
                min-height: 40px;
                border: none;
                width: 166px;

                &:hover {
                    background: rgb(153, 224, 20);
                }
            }

            ul {
                list-style-image: url("../../public/assets/list.png");
                li {}
            }

            p {

                    font-size: 15px;
                        font-weight: 400;
                        color: rgba(63, 66, 84, 1);
            }
        }

        .column-two {
            background-color: rgb(104, 16, 199);
            border-style: dashed;
            border-width: 1px;
            border-color: rgba(255, 255, 255, 1);
            border-radius: 20px;
            padding: 70px 30px 70px 30px;
            color: #ffff;
            p{
                color: #ffff;
            }
        }
    }

    .faq-container {
        display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

        h1 {
            font-size: 30px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
        }

        .question-container {
            box-shadow: 0px 3px 10px 0px rgb(204 204 204 / 35%);
            padding: 30px 30px 30px 30px;
            border-radius: 10px;
            margin-top: 30px;
            background-color: rgb(255, 255, 255);
            width: 60%;
            text-align: left;
            .question {
                font-size: 19px;
                font-weight: 500;
                color: rgba(17, 24, 39, 1);
                padding: 0;
                text-align: left;
            }

            .answer {
                text-align: left;
                font-size: 15px;
                font-weight: 400;
                color: rgba(107, 114, 128, 1);
                margin: 0;
            }
        }
    }

    .support-container {
            background-color: rgb(101, 16, 193);
                border-radius: 10px;
                p{
                        font-size: 15px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 1);
                            text-align: center;
                }
    }
}