
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url("./styles/login.scss");
@import url("./styles/account.scss");
@import url("./styles/coverLetter.scss");
@import url("./styles/AuthNavbar.scss");
@import url("./styles/pricePage.scss");
@import url("./styles/howPage.scss");
@import url("./styles/footer.scss");


body{
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
  // overflow: hidden;
}

a{
  text-decoration: none;
}

button{
  outline: none;
}


input{
  &:active, &:focus{
   border:1px solid rgb(101, 16, 193) !important;
    box-shadow: none !important;
  }
}

textarea {
  &:active,
  &:focus {
    border: 1px solid rgb(101, 16, 193) !important;
    box-shadow: none !important;
  }
}
